.Well_Done {
    display: flex;
    justify-content: flex-end;
    width: 95vw;
    font-size: 12vh;
    padding-top: 4vh;
    padding-bottom: 4vh;

}
.Thanks{
    display: flex;
    justify-content: flex-end;
    transform: translate(-50%, -50%);
    left: 50%;
    top: 50%;
    width: 95vw;
    font-size: 12vh;
    position: absolute;
}

.thank_you_message {
    height: 40%;
    margin-left: 7vw;
    cursor: pointer;
    margin-top: 5vh;
    margin-bottom: 10vh;
}
.feedback_buttons {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: auto;
}
.happy {
    background-image: url("../Assets/ThankYou_Page/happy.png");
    background-size: contain;
    background-repeat: no-repeat;
    height: 15vh;
    width: 18vw;
    background-color: transparent;
    border-color: transparent;
    background-position: center;
    cursor: pointer;

}

.sad {
    background-image: url("../Assets/ThankYou_Page/sad.png");
    background-size: contain;
    background-repeat: no-repeat;
    height: 15vh;
    width: 18vw;
    background-color: transparent;
    border-color: transparent;
    background-position: center;
    cursor: pointer;

    
}

.satisfied {
    background-image: url("../Assets/ThankYou_Page/satisfied.png");
    background-size: contain;
    background-repeat: no-repeat;
    height: 15vh;
    width: 18vw;
    background-color: transparent;
    border-color: transparent;
    background-position: center;
    cursor: pointer;


}
.ThankYou_background {
    justify-content: center;
    height: 100vh;
    width: 100%;
    background-image: url('../Assets/ThankYou_Page/ThankYou_Background.png');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    flex-direction: column;
}

.feedback {
    height: 25vh;
    width: 100vw;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.cat{
    transform: translate(20%,-50%);
    top: 50%;
    margin-left: 5vh;
    scale: 1.2;
    position: absolute;
}


.feedback-buttons {
    display: flex;
    justify-content: center;
    align-items: center;
}

.feedback-text {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 5vw;
}

@media (max-height : 820px) {
    .thank_you_message {
        height: 30%;
        margin-left: 7vw;
        cursor: pointer;
        margin-top: 5vh;
        margin-bottom: 8vh;
    }
}