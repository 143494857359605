.return_to_home {
    background-image: url("../Assets/modal_graphics/home_icon.png");
    height: 12vh;
    width: 15vw;
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    border-color: transparent;
    background-color: transparent;
    cursor: pointer;
}

.fb_message {
    cursor: pointer;
}