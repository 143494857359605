.modal {
  display: none; 
  position: fixed; 
  z-index: 2; 
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 100vw; 
  height: 100vh; 
  padding: 20px; 
  background-color: rgba(0, 0, 0, 0.5); 
  border-radius: 20px; 
}

@media screen and (max-width: 767px), screen and (max-height: 767px) {
  .modal {
      display: flex; 
      justify-content: center;
      align-items: center;
  }
}

.message {
  margin-top: 15vh;
  height: 50vh;
  width: 50vw;
  background-color: white;
  box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2); 
  border-radius: 20px;
  border-width: 0.4vh;
  border-color: black;
  border-style: solid;
  opacity: 85%;

  display: flex;
  justify-content: center;
  align-items: center;
  padding: 2vw;
}
