.home_background {
    display: flex;
    height: 100vh;
    width: 100vw;
    flex-direction: column;
    background-image: url("../Assets/Home_Page/final_background.svg");
    background-repeat: no-repeat;
    background-size: cover; 
    background-position: center;
    z-index: 0;
}

.home_instructions {
    cursor: pointer;
    height: 30vh;
    width: 45vw;
    margin-top: 5vh;
    padding-left: 2vw;
    margin-bottom: 10vh;
}

.InteractionButton{

    animation-duration: 1s;
    animation-name: pulse;
    animation-iteration-count: infinite;
    animation-direction: alternate;

    height: 100vw; 
    width: 100vw;
    size-adjust: 10;
    border-radius: 5vw;
    border: #AEF597;
    background-color: #AEF597;
    justify-content: center;
    left: 50%;
    top: 51%;
    transform: translate(-50%, -50%);
    align-items: center;
    border: black;
    border-style: solid;
    border-width: 1vh;
    justify-content: center;
    font-size: 5vw;
    color: black;
    display: flex;
    position: absolute;
    z-index: 2;
    align-items: center;

}
.Home_buttons {
    display: flex;
    align-items: flex-end; /* Center items vertically within the container */
    justify-content: space-around; /* Distribute space around items */
    flex-direction: row;
    width: 95vw;
    z-index: inherit;
}

.Start_Home {
    height:15vh;
    width: 29.5vw;
    scale: 1.5;
    z-index: 1;
    position: absolute;
    right: 0;
    top: 54%;
    transform: translate(-23%, -50%);
}

.About_Us {
    display: flex;
    justify-content: flex-start;
    margin-left: 2vw;
    text-decoration: underline;
    font-size: "10vw";
    height: 10vh;
}
.animatedCat{
    scale: 50%;
    animation-duration: 1.5s;
    animation-name: cat;
    animation-iteration-count: infinite;
    animation-direction: alternate;
    position: absolute;
    filter: drop-shadow(0 3mm 4mm black);
    z-index: 2;
}
  
  @keyframes pulse {
    from {
      scale: 100%;
    }
  
    to {
      scale: 105%;
      left: 52%;
      top: 51%;
    }
  }
  @keyframes cat{
    from {
        transform: translate(0, -75%);
    }
  
    to {
        transform: translate(0, -90%);
    }
  }

@media (max-height: 821px) {
    .home_instructions {
        cursor: pointer;
        height: 25vh;
        width: 30vw;
        margin-top: 5vh;
        padding-left: 7vw;
        margin-bottom: 2vw;
    }
    
    .Start_Home {
        height: 12vh;
        width: 25vw;
    }
}



