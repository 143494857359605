.Viscat_MSG {
  height: 14vh;
  width: 30vw;
  display: flex;
  position: absolute;
  justify-content: center;
  padding-left: 6vw;
  cursor: pointer;
}
.cssButton{
  cursor: pointer;
  position: absolute;
  margin: 0;
  scale: 1.2;
  padding-block: 1vh;
  padding-inline: 2vw;
  font-size: 3.5vh;
  border-radius: 1vw;
  border: black;
  border-style: solid;
  border-width: 0.25vh;
  background-color: #AEF597;
  justify-content: center;
  align-items: center;
  transform: translate(0, 50%);
  z-index: 2;
}
.cssButtonOutline{
  cursor: pointer;
  position: absolute;
  margin: 0;
  scale: 1.2;
  padding-block: 1vh;
  padding-inline: 2vw;
  font-size: 3.5vh;
  border-radius: 1vw;
  border: black;
  border-style: solid;
  border-width: 0.25vh;
  background: #5DCB39;
  justify-content: center;
  transform: translate(0, 60%);
  z-index: 1;
}
.cssButtonOFF{
  position: absolute;
  margin: 0;
  scale: 1.2;
  padding-block: 1vh;
  padding-inline: 2vw;
  font-size: 3.5vh;
  border-radius: 1vw;
  border: black;
  border-style: solid;
  border-width: 0.25vh;
  background-color: darkgrey;
  justify-content: center;
  align-items: center;
  transform: translate(0, 50%);
  z-index: 2;
}
.cssButtonOutlineOFF{
  position: absolute;
  margin: 0;
  scale: 1.2;
  padding-block: 1vh;
  padding-inline: 2vw;
  font-size: 3.5vh;
  border-radius: 1vw;
  border: black;
  border-style: solid;
  border-width: 0.25vh;
  background: gray;
  justify-content: center;
  transform: translate(0, 60%);
  z-index: 1;
}

.container {
  display: flex;
  flex-direction: column;
  background-color: #77D2EF;
  height: 100vh;
  overflow: hidden;
  margin: 0;
  justify-content: space-between; /* This will push the last child to the bottom */
  align-items: center;
}
  
  .game_box {
    display: flex;
    justify-content: center;
    flex-direction: column;
    margin-left: auto;
    margin-right: auto;
    height: 80vh;
    width: 90vw;
    background-color: whitesmoke;
    border-radius: 20px;
    border-style: solid;
    border-color: black;
    border-width: 10px;
    overflow: hidden;
    margin-top: 10vh;
    margin-bottom: auto;
  }

  .game_box_practice {
    display: flex;
    justify-content: center;
    flex-direction: column;
    margin-left: auto;
    margin-right: auto;
    height: 80vh;
    width: 90vw;
    background-color: #FFE6F8;
    border-radius: 20px;
    border-style: solid;
    border-color: black;
    border-width: 10px;
    overflow: hidden;
    margin-top: 10vh;
    margin-bottom: auto;
  }
  
  .left_grid, .right_grid {
    flex: 1; /* Each grid will take up equal space */
    display: flex;
    justify-content: center; 
    align-items: center;
    padding: 1.5%;
  }

.instructions_gif {
  display: flex;
  justify-content: center; 
  align-items: center;
  padding: 1.5%;
  width: auto;
}

  .divider {
    height: 80vh;
    width: 0.5vh; 
    background-color: black;
    border-radius: 20px;
    border-width: 0.4vh;
    border-color: white;
    border-style: solid;
  }

  .divider_practice {
    height: 80vh;
    width: 0.5vh; 
    background-color: black;
    border-radius: 20px;
    border-width: 0.4vh;
    border-color: #FFE6F8;
    border-style: solid;
  }



  .buttons {
    height: 10vh;
    width: 95vw;
    display: flex;
    justify-content: space-between; 
    margin: 2vmin;   
  }

  .sound_button {
    margin-left: 2vw;
    height: 6vh;
    cursor: pointer;
  }


  .Reverse {
    margin-right: 1vw;
    height: 12vh;
    cursor: pointer;

  }

  .Reset {
    margin-right: 1vw;
    margin-left: 2vw;
    height: 12vh;
  }

  .bottomButtons {
    height: 20vh;
    scale: 1.1;
    width: 100vw;
    justify-content: center;
    display: flex;
  }
  
  .done{
    width: 30vw;
    scale: 1.1;
    transform: translate(44vw, 0);
    z-index: 3;
  }

  .submitButton {
    height: 12vh;
    width: auto;
    margin-left: 2.5vh;
    cursor: pointer;
    margin-top: 5vh;
  }

  .toggleButton {
    height: 12vh;
    width: auto;
    margin-left: -2.5vh;
    cursor: pointer;
    height: 12vh;
    margin-top: 5vh;
  }

.pattern-lock-container {
  transform: scale(1); 
  transform-origin: center;
}

.grids {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  
}


div.react-pattern-lock__point-inner{
  background-color: black;
}

div.react-pattern-lock__connector{
  background-color: black;
}


.Level {
  margin-right: 2vw;
  display : flex;
  align-items: center;
}

.toggle_button {
  height: 12vh;
  width: 20vw;
  cursor: pointer;
  margin-top: 5vh

}

@media (max-height: 820px) { 
  .left_grid, .right_grid {
    flex: 1;
    height: 25vh;
    display: flex;
    justify-content: center; 
    align-items: center;
  }
  .toggle_button {
    height: 10vh;
    cursor: pointer;
    margin-top: 2vh
  }
  .submitButton {
    height: 10vh;
    cursor: pointer;
    margin-top: 2vh;
  }
  .instructions_gif {
    height:62vh;
  }
  .toggleButton {
    height: 10vh;
    margin-right: 7vmin;
    cursor: pointer;
    margin-top: 2vh

  }
  .game_box {
    display: flex;
    flex-direction: column;
    height: 65vh;
    width: 95vw;
    background-color: whitesmoke;
    border-radius: 20px;
    border-style: solid;
    border-color: black;
    border-width: 10px;
    overflow: hidden;
    margin-bottom: auto;
  }
  .buttons {
    height: 2vh;
    width: 95vw;
    display: flex;
    justify-content: space-between;  
  }
}

* {
  -webkit-tap-highlight-color: transparent;
}

:focus {
  outline: none;
}

/* from SEP FILE comp*/

.button_modal {
  position: fixed; 
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent background */
  z-index: 2; /* High z-index to ensure it's on top */
}

.button_modal_content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: white;
  padding: 2vmin;
  border-radius: 20px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.3);
  width: 70vw;
  height: 45vh;
}

.Buttons {
  padding-top: 3vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal_message{
  height: 25vh;
  cursor: pointer;
}